@import '../colors';
@font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    src: local('Montserrat Regular'), url('../../static/fonts/montserrat/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'Montserrat-Bold';
    font-style: bold;
    src: local('Montserrat Bold'), url('../../static/fonts/montserrat/Montserrat-Bold.ttf');
}
html {
    background-color: $background-dark;
    -webkit-font-smoothing: antialiased;
}
body {
    font-family: "Montserrat-Regular";
    margin: 0;
    color: $text-dark;
    background-color: $background-dark;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::selection {
        color: $background-dark;
        background: $accent-dark;
        }
    ::-moz-selection {
    color: $background-dark;
    background: $accent-dark; 
    }
    a, a:visited {
        color: $text-dark;
        text-decoration: none;
        transition: color 0.125s ease-in;
    }
    a:hover {
        color: $accent-dark;
    }
}