@import '../colors';
.lightbox {
    @media screen and (max-height: 800px) {
        overflow-y: auto !important;
    }
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin:auto;
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(18,18,18,.8);
    text-align: center;
    transition: visibility .5s, opacity .5s linear;
    .genre-list {
        margin-top: 0;
        h2 {
            margin-top: 0;
        }
    }
    .external-links {
        padding-top: .25em;
        padding-bottom: .5em;
        a {
            width: 1.5em;
            padding-right: .25em;
            padding-left: .25em;
            text-decoration: none;
            font-weight: bolder;
        }
        svg {
            height: auto;
            width: 1.5em;
        }
    }
    .close-icon {
        transition: color 0.125s ease-in;
        text-align: unset;
        left: 0;
        display: initial;
        position: absolute;
        margin: .5em;
        text-decoration: none;
        margin-top: 1.4em;
        padding: .25em;
        width: 2em;
        &:hover {
            cursor: pointer;
            color: $accent-dark;
        }
    }
    #last_link:hover {
        color: $lastfm-color;
    }
    #spot_link:hover {
        color: $spotify-color;
    }
    .track-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        .track-container {
            div:first-child{
                margin-top: .75em;
            }
            max-width: 25em;
            justify-content: left;
            @media screen and (max-width: 400px) {
                max-width: 300px;   
            }
        }
    }
    .artist-info {
        overflow-y: scroll;
    }
    .track {
        #track-name {
            align-self: center;
            overflow-x: hidden;
            overflow-y: hidden;
            text-overflow: ellipsis;
        }
        justify-content: left;
        align-content: center;
        flex-direction: row;
        display: flex;
        height: 1.25em;
        margin: 1.25em;
        .track-icon {
            transition: color 0.125s ease-in;
            width: 1em;
            height: auto;
            color: white;
            &:hover {
                color: $accent-dark;
                cursor: pointer;
            }
        }
        h4 {
            height: 1.25em;
            white-space: nowrap;
        }
        button {
            font-size: 1.5em;
            padding: .25em;
            align-self: center;
            border: white;
            stroke: white;
            border-radius: 50%;
            background: none;
        }
        .pause-icon {
            display: none;
        }
        .play-icon {
            display: block;
        }
        &.audio-playing {
            .play-icon {
                display: none;
            }
            .pause-icon {
                display: block;
            }
        }
    }
    .lightbox-title {
        text-align: center;
        margin: .75em auto 0;
        @media screen and (max-width: 475px) {
            word-break: break-word;
            max-width: 250px;
        }
    }

    .lightbox-container {
        padding-bottom: 5em;
        a{
            padding-right: 1em;
        }
        margin:auto;
        width:50%;
        text-align: center;
        .lightbox-image {
            width: 200px;
            height: 200px;
            background-position: center; 
            background-repeat: no-repeat; 
            background-size: cover;
            margin: 0 auto;
            border-radius:50%;
            -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.5); 
            box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.5);
        }
        ul{
            h2 {
                margin-bottom: 0;
            }
            list-style-type:none;
            padding-left: 0;
        }
    }
}
