@import '../colors';

#artist-title {
    word-break: break-word;
    font-weight: 900;
    margin-top: .35em;
    text-align: center;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(38,38,38,.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: .40em;
    border-radius: 30px;
    animation: fadein 1s ease-in;
    text-decoration: none;
    transition: visibility .5s, opacity .5s linear, color 0.25s, background-color 0.25s ease-in;
    &:hover {
        cursor: pointer;
        color: $accent-dark;
    }
    @media screen and (max-width: 400px) {
        margin-top: .9em;
        font-size: 1.5em;
        max-width: 250px;
    }
}
#source-artist-image {
    opacity: 0;
    z-index: 1;
    animation: fade 2s ease-in;
    position: fixed;
    top: 50%;
    left: 50%;
    @media screen and (min-width: 650px) {
        width: 20vw;
        height: 20vw;
    }
    @media screen and (max-width: 649px) {
        width: 50vw;
        height: 50vw;
    }

    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.5);
}

body {
    overflow: hidden;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes fade {
    0% { opacity: 0; }
    25% { opacity: 1;}
    100% { opacity: 0; }
}