#not-found {
    #back-link {
        display: unset;
    }
}
.message {
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 10em;
    svg {
        width: 170px;
    }
    h1 {
        font-family: 'Montserrat-Bold';
        font-size: 2rem;
        padding-bottom: .25em;
        padding-top: .25em;
    }
    & > * {
        text-align: center;
        display: block;
        margin: auto;
    }
}