@import '../colors';

#home-search{
    animation: fadein 1s;
    input {
        background-image: url(../../static/icons/search.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-position-x: 2%;
        background-size: 7%;
        background-color: white;
        text-indent: 1.25em;
        margin: 0;
        width:400px;
        @media screen and (max-width: 770px) {
            font-size: 1.25em;
            width: 280px;
            text-indent: 1.5em;
            padding: .5em .25em;
        }
        background-color: #232323;
        color: white;
        font-size: 1.75em;
        border-radius: 30px;
        border-color:transparent;
        padding: .25em .25em;
        outline: none;
        &.populated {
            border-radius: 30px 30px 0px 0px;
            border-bottom-color: rgba(255,255,255,.1);
        }
    }
}
input + .suggestions-container{
    background-color: #232323;
    a {
        padding: .25em 0 .25em .5em;
        display: block;
        text-decoration: none;
    }
    a:hover {
        background-color: rgba(255,255,255,.1);
    }
    padding: 1em 0 1.25em 0;
    border-radius: 0px 0px 30px 30px;
}
input + #suggestion-box{
    display: none;
}
input + #suggestion-box.populated{
    display: block;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}