.searchbar-container {
  z-index: 1;
  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    top: 1em;
  }
  margin:auto;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  h1 {
    font-size: 60px; 
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
  }
  svg {
    width: 150px; 
    margin: auto;
    display: block;
  }
}
.app-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  p {
      font-size: .9em;
      display: block;
      margin: 1em auto;
      a {
          font-family: "Montserrat-Bold";
          font-weight: 800;
      }
  }
}
.help-link {
    animation: fadein 2s ease-in;
    display: initial;
    position: absolute;
    right: 0;
    margin: 1em;
    text-decoration: none;
    opacity: 1;
    visibility: unset;
    transition: visibility .5s, opacity .5s linear, color 0.25s ease-in;
    #help-button {
        padding: .25em;
        width: 2em;
    }
}