#back-link {
    animation: fadein 2s ease-in;
    display: initial;
    position: absolute;
    margin: .5em;
    text-decoration: none;
    opacity: 1;
    visibility: unset;
    transition: visibility .5s, opacity .5s linear, color 0.25s ease-in;
    #back-button {
        margin-top: .9em;
        padding: .25em;
        width: 2em;
    }
}

.wrapper.lightbox-visible {
    #back-link {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}