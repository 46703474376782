@import '../colors';
#help {
    animation: fadeHelp 1s ease-in;
    height: 100vh;
    overflow-y: auto !important;
}
.help-wrapper {
    b {
        font-family: 'Montserrat-Bold'
    }
    @media screen and (max-width: 500px) {
        padding-bottom: 5em;
    }
    padding-bottom: 2em;
    padding-top: 1em;
    text-align: center;
    margin: auto;
    width: 50%;
    h1 {
        font-size: 3em;
        margin-top: -.05em;
        margin-bottom: 1em;
    }
    .logo-container {
        height: 300px;
        display: flex;
        #logo {
            width: 300px !important;
            margin: 0 auto;
            animation: zoom 2s ease-in infinite alternate;
            display:block;
            align-items: center;
        }
    }
    .animation-container {
        margin-left: -50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .explanation{
        width: 100%;
        p{
            max-width: 400px;
            text-align: center;
            margin: auto;
        }
        
    }
    .artist-demo {
        margin-top: 4em;
        .name-demo {
            margin: auto;
            width: 100px;
            font-size: 1.5em;
            background-color: rgba(38,38,38,.8);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            padding: .5em;
            border-radius: 30px;
            transition:color 0.25s ease-in;
            &:hover {
                cursor: pointer;
                color: $accent-dark;
            }
        }
        .explanation {
            margin-top: 1em;
        }
    }
    .hover-demo {
        h5 {
            margin: 0;
            margin-top: 0;
            animation: fadeHelp 2s ease-in infinite alternate;
        }
        p {
            max-width: 200px;
            margin: .25em;
        }
        h3 {
            margin-bottom: .5em;
        }
        margin-top: 4em;
        .explanation {
            flex-flow: column;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }
        svg{
            &:hover {
            cursor: pointer;
            
            }
        }
    }
    #tooltip{
        opacity: 0;
        background-color: rgba(38, 38, 38, 0.8);
        border-radius: 30px;
        padding: 12px;
        color: white;
        position: absolute;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
    #enjoy {
        padding-top: 2em;
        padding-bottom: .5em;
        margin:0;
    }
    @keyframes zoom {
        from { transform: scale(1) }
        to   { transform: scale(.75) }
    }
    @keyframes fadeHelp {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}